import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby'

import Layout from '../components/layout';
import Footer from '../components/footer';
//import indexStyle from '../components/'

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { Link } from 'gatsby';
import { getImage, GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import getImageList from '../hooks/get-image-list';
import "../styles.scss";
import "../css/masthead-style.css"
import "../css/index-extra-styles.css"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'
import useNavigation from "../hooks/use-navigation"
import useSiteMetadata from "../hooks/use-site-metadata"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Ratio from 'react-bootstrap/Ratio'
import CardGroup from 'react-bootstrap/CardGroup'
import cmf_image from '../images/cmf_image.png'
import incus_sat from '../images/incus_sat.png'
import incus_sat_bw_169 from '../images/incus_sat_bw_169.png'
import iss_storms from '../images/iss_tstorms_2.jpeg'
import incus_sat_row from '../images/incus_diagram.png'
import incus_rendering from '../images/INCUS_rendering.jpg'
import iss_convective_storm_single from '../images/iss-convective-storm-single.jpeg'
import DateCountdown from 'react-date-countdown-timer';
import Countdown from 'react-countdown';
import Header from "../components/header"
import sue_vdh_pic from '../images/sue_vdh_pic.jpg'
import blank_person_pic from '../images/blank_person.png'
import { Splide, SplideSlide } from '@splidejs/react-splide';
// Default theme
import '@splidejs/react-splide/css';



const IndexPage = () => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "iss_sahara_cropped.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality:80
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  //Get image and convert to background image
  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  const meta = useSiteMetadata()




  return (
    <React.Fragment>
      <Header meta={meta} navTransparent={true} />
      <main>
        <BackgroundImage
          Tag="section"
          className="masthead-image"
          // Spread bgImage into BackgroundImage:
          style={{
            // Defaults are overwrite-able by setting one or each of the following:
            backgroundPosition: 'top'
          }}

          {...bgImage}

        >   <div className='masthead-overlay'>
            <div className='masthead-content-box'>
              <h1 style={{ textShadow: '0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white', fontSize: '5rem' }}>INCUS</h1>
              <h3 style={{ textShadow: '0 0 2px white, 0 0 2px white, 0 0 2px white, 0 0 2px white' }}>NASA's Storm Chaser</h3>
            </div>
          </div>
        </BackgroundImage>
        <Container className='pb-3'>
          <Row>
            <CardGroup>


              <Card>
                <div className="card-img-top" style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: "56.25%"
                }}>
                  <iframe width="100%" height="100%" src="https://www.youtube.com/embed/R-7fOMYSXg4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      border: "0"

                    }}>
                  </iframe>
                </div>

                <Card.Body>
                  <Card.Title><strong>The INCUS Mission</strong></Card.Title>
                  <Card.Text>
                    the INvestigation of Convective UpdraftS (INCUS) mission was competitively selected as the 3rd <a href="https://essp.nasa.gov/" target="_blank">NASA Earth Ventures Mission (EVM-3)</a>. INCUS will provide the first tropics-wide investigation of the evolution of the vertical transport of air and water by convective storms (convective mass flux), one of the most influential, yet unmeasured atmospheric processes.
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col className='d-flex justify-content-center'>
                      <Link to="mission/overview">
                        <Button variant="primary">Overview</Button>
                      </Link>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                      <Link to="mission/mission-instruments">
                        <Button variant="primary">Specs</Button>
                      </Link>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                      <Link to="timeline">
                        <Button variant="primary">Timeline</Button>
                      </Link>
                    </Col>

                  </Row>


                </Card.Footer>

              </Card>
              <Card>
                <div className="card-img-top" style={{ backgroundColor: "#c9e2ff" }}>
                  <StaticImage
                    alt="a conceptual diagram of the three INCUS satellites orbiting over earth"
                    src="../images/clean_incus_example.png"
                    layout="constrained"
                    placeholder="blurred"
                    objectPosition="0 0"
                    aspectRatio={16 / 9}
                    objectFit="contain"
                    transformOptions={{ fit: "contain" }}
                    backgroundColor="transparent"

                  />
                </div>

                <Card.Body>
                  <Card.Title><strong>INCUS Science</strong></Card.Title>
                  <Card.Text>
                    The goal of INCUS is to understand why, when and where tropical convective storms form, and why only some storms produce extreme weather. If you want to learn more about INCUS science see a summary by clicking “Learn More."
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Row>
                    <Col className='d-flex justify-content-center'>
                      <Link to="mission/science-goals-objectives">
                        <Button variant="primary">Learn More</Button>
                      </Link>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                      <Link to="publications">
                        <Button variant="primary">Publications</Button>
                      </Link>
                    </Col>


                  </Row>


                </Card.Footer>

              </Card>
            </CardGroup>
          </Row>
          <Row>
            <CardGroup>

              <Card>
                <div className="card-img-top">
                  <StaticImage
                    alt="a diagram of convective mass flux over a picture of a thunderstorm"
                    src="../images/iss-convective-storm-single.jpeg"
                    layout="constrained"
                    placeholder="blurred"
                    objectPosition="0 0"
                    aspectRatio={16 / 9}
                    objectFit="cover"
                    transformOptions={{ fit: "cover" }}
                    backgroundColor="transparent"

                  />
                </div>

                <Card.Body>
                  <Card.Title><strong>INCUS Products</strong></Card.Title>
                  <Card.Text>
                    INCUS will provide a wide range of products to enhance our understanding of convective storm processes and our capabilities to model them in current and future climates.
                  </Card.Text>
                </Card.Body>
                <Card.Footer className='banner-card-footer'>
                  <Row>
                    <Col className='d-flex justify-content-center'>
                      <Link to="products">
                        <Button variant="primary">Products</Button>
                      </Link>
                    </Col>

                  </Row>


                </Card.Footer>

              </Card>

              <Card>
                <div className="card-img-top">
                  <StaticImage
                    alt="a picture of the INCUS Science Team at the 2022 INCUS Science Team Meeting in Fort Collins, CO"
                    src="../images/incus_stm_2022.jpg"
                    layout="constrained"
                    placeholder="blurred"
                    objectPosition="0 0"
                    aspectRatio={16 / 9}
                    objectFit="cover"
                    transformOptions={{ fit: "cover" }}
                    backgroundColor="transparent"

                  />
                </div>

                <Card.Body>
                  <Card.Title><strong>INCUS Team</strong></Card.Title>
                  <Card.Text>
                    The INCUS team is comprised of scientists and engineers from 11 universities, 3 NASA laboratories, 2 industry partners, and 7 other meteorological centers, institutes and organizations.
                  </Card.Text>
                </Card.Body>
                <Card.Footer className='banner-card-footer'>
                  <Row>
                    <Col className='d-flex justify-content-center'>
                      <Link to="mission/team">
                        <Button variant="primary">Meet the Team</Button>
                      </Link>
                    </Col>
                    <Col className='d-flex justify-content-center'>
                      <Link to="mission/partners">
                        <Button variant="primary">Partners</Button>
                      </Link>
                    </Col>


                  </Row>


                </Card.Footer>

              </Card>

            </CardGroup>
          </Row>
          <h2 className='pt-4'>INCUS News</h2>
          <Splide aria-label="My Favorite Images"
            options={{
              perPage: 4,
              breakpoints: {
                768: {
                  perPage: 1
                }
              }

            }}


          >

            <SplideSlide>
              <a href="https://engr.source.colostate.edu/incus-milestone-nasa-selects-texas-firm-as-launch-service-for-csu-led-mission/" target="_blank">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="An image of three INCUS satellites overpassing convection"
                      src="../../images/incus_diagram.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      NASA selects Firefly Aerospace Inc. as launch service for INCUS
                    </Card.Title>
                    <Card.Text>
                      NASA has selected Firefly Aerospace Inc. of Cedar Park, Texas, to provide the launch service for the agency’s Investigation of Convective Updrafts (INCUS) mission, led by University Distinguished...</Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on March 5, 2025</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </a>


            </SplideSlide>


            <SplideSlide>
              <Link to="/news/time-slice-co-kicks-off">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="The INCUS logo"
                      src="../../images/time-slice/disdrometer_higherres.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      TIME-SLICE CO Kicks Off
                    </Card.Title>
                    <Card.Text>
                      On a warm late spring day in Northern Colorado, a radar groans as it rapidly turns from pointing at one storm to another. That radar is the Colorado State University C-band Hydrological Instrument for Volumetric Observation radar...</Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on June 14, 2024</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </Link>


            </SplideSlide>

            <SplideSlide>

              <a href="https://engr.source.colostate.edu/csu-led-satellite-mission-to-study-extreme-weather-moves-into-construction-phase/" target="_blank">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="An image of thunderstorms"
                      src="./news/images/ksc-2014-3582_large.jpeg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      CSU-led satellite mission to study extreme weather moves into construction phase
                    </Card.Title>
                    <Card.Text>
                      From SOURCE -- A Colorado State University-led effort to study storms and extreme weather from space using small satellites passed a key review by NASA in November 2023...
                    </Card.Text>
                    <Card.Footer>

                      <small className='text-muted'>Posted on May 6, 2024</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </a>
            </SplideSlide>

            <SplideSlide>

              <Link to="/news/incus-passes-kdp-c">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="The INCUS logo"
                      src="../images/incus_logo_full_color.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      INCUS Passes KDP-C
                    </Card.Title>
                    <Card.Text>
                      INCUS Key Decision Point-C (KDP-C) was held at NASA HQ On November 14, 2023. Congratulations to the INCUS team for successfully passing KDP-C and moving the INCUS mission into Phase C. The move into Phase C confirms the INCUS...</Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on November 14, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </Link>
            </SplideSlide>


            <SplideSlide>
              <Link to="/news/incus-passes-pdr-sept-2023">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="A rendering of three INCUS satellites passing over earth. "
                      src="../images/incus_pdr_team_2023.jpg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      INCUS Passes Preliminary Design Review
                    </Card.Title>
                    <Card.Text>
                      The INvestigation of Convective UpdraftS (INCUS), a NASA Earth Ventures Mission led by Colorado State University (CSU) and managed by NASA's Jet Propulsion Laboratory, has passed its Preliminary Design...</Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on September 21, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </Link>

            </SplideSlide>


            <SplideSlide>
              <a href="https://www.9news.com/article/news/state/colorado-climate/forecast-thunderstorms/73-fd419fe0-bf8e-405d-9bcd-699c1d0ec50f/" target='_blank'>
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="A rendering of three INCUS satellites passing over earth. "
                      src="../images/incus_diagram.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      INCUS Mission Spotlighted in 9NEWS Report
                    </Card.Title>
                    <Card.Text>
                      Denver-based 9NEWS interviewed INCUS Co-Investigator Kristen Rasmussen about the upcoming NASA/CSU satellite mission, focusing on INCUS's role in understanding thunderstorms from space...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on June 26, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </a>

            </SplideSlide>


            <SplideSlide>
              <a href="https://www.cbsnews.com/colorado/video/nasa-awards-177m-to-csu-for-first-of-its-kind-incus-weather-satellites/" target='_blank'>
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="A rendering of three INCUS satellites showing them passing over convective storms, with radar profiles, over earth. "
                      src="../images/incus_animation_snapshot.jpeg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      CBS Colorado Features INCUS Mission
                    </Card.Title>
                    <Card.Text>
                      KCNC CBS Colorado featured a report on the INCUS mission, and in particular the leadership from Colorado State University, on their 6pm newscast, including interviewing Co-Investigator Kristen Rasmussen...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on May 5, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </a>

            </SplideSlide>

            <SplideSlide>
              <Link to="news/incus-denver-gazette-may-2023">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="A rendering of three INCUS satellites overflying convective storms on Earth"
                      src="../images/INCUS_rendering.jpg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      INCUS Highlighted in Denver Gazette and by Gov. Polis
                    </Card.Title>
                    <Card.Text>
                      The INvestigation of Convective UpdraftS (INCUS), a NASA Earth Ventures Mission led by Colorado State University (CSU) and managed by NASA's Jet Propulsion Laboratory, was highlighted in a recent Denver...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on May 2, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </Link>

            </SplideSlide>

            <SplideSlide>
              <Link to="news/incus-international-presentations">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="Benjamin Donitz, an Advanced Design Systems Engineer at NASA’s Jet Propulsion Laboratory, gives a presentation on INCUS at the COmmittee on SPAce Research (COSPAR) 2023 conference on small satellites"
                      src="./news/images/benjamin_donitz_cospar.jpg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      INCUS Presented at International Conferences
                    </Card.Title>
                    <Card.Text>
                      Several members of the INCUS Science Team recently gave international presentations on the current developments in the INCUS mission. Susan van den Heever, PI of the INCUS mission, presented on...

                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on April 28, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </Link>

            </SplideSlide>


            <SplideSlide>
              <Link to="news/incus-passes-kdp-b">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="The INCUS logo"
                      src="../images/incus_logo_full_color.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      INCUS Team Passes KDP-B
                    </Card.Title>
                    <Card.Text>
                      The INCUS Key Decision Point-B was held at NASA HQ On March 14, 2023. Congratulations to the INCUS team for successfully passing the KDP-B and moving the INCUS mission into Phase B. The purpose of Phase B is for the project team to complete the...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on March 14, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </Link>

            </SplideSlide>


            <SplideSlide>
              <Link to="news/incus-passes-srr-mdr">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="a rendering of the three INCUS satellites passing over the earth"
                      src="../images/incus_diagram.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      INCUS Team Passes SRR/MDR
                    </Card.Title>
                    <Card.Text>
                      Sandra Cauffman, Chair of the INCUS Science Review Board (SRB), has stated in the INCUS SRB preliminary report that  “The SRB conclusion is that the project has passed the SRR/MDR [Science Readiness Review/Mission Design Review].”
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on February 2, 2023</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </Link>

            </SplideSlide>
            <SplideSlide>
              <a href="https://engr.source.colostate.edu/csu-hosts-science-team-meeting-for-nasa-satellite-mission-incus/" target="_blank">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="a photo of the INCUS science team at the CSU Stadium"
                      src="./news/images/INCUSstm_group_1920.jpg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      CSU hosts science team meeting for NASA satellite mission INCUS
                    </Card.Title>
                    <Card.Text>
                      From SOURCE-- Before there can be NASA satellite launches, there are planning meetings. Lots of planning meetings. The first such all-hands meeting for the Colorado State...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on October 17, 2022</small>
                    </Card.Footer>
                  </Card.Body>

                </Card>
              </a>

            </SplideSlide>


            <SplideSlide>
              <a href="https://climate.nasa.gov/news/3141/one-year-into-the-biden-administration-nasa-looks-to-future/" target="_blank">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="a black and white image of the INCUS satellite"
                      src="./news/images/vp_harris_nasa.jpg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      One Year into the Biden Administration, NASA Looks to Future
                    </Card.Title>
                    <Card.Text>
                      From NASA-- Over the past year, NASA has made valuable contributions to the Biden-Harris Administration’s goals – leading on the global stage, addressing the...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on January 26, 2022</small>
                    </Card.Footer>
                  </Card.Body>
                </Card>
              </a>
            </SplideSlide>

            <SplideSlide>
              <a href="https://www.washingtonpost.com/science/nasa-incus-tropical-storms/2021/11/12/3ef61544-4255-11ec-a88e-2aa4632af69b_story.html" target="_blank">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="a conceptual diagram showing three satellites overflying a line of convective clouds, illuminating them with their radar beams"
                      src="../images/incus_diagram.png"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      NASA greenlights study of tropical storms
                    </Card.Title>
                    <Card.Text>
                      From the Washington Post-- With human-caused climate change come warmer oceans — and in turn, researchers predict, they&#39;ll fuel increasingly destructive storms. But how do tropical storms...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on November 14, 2021</small>
                    </Card.Footer>
                  </Card.Body>


                </Card>
              </a>
            </SplideSlide>
            <SplideSlide>
              <a href="https://engr.source.colostate.edu/csu-atmospheric-scientists-lead-177-million-nasa-mission-to-study-thunderstorms-in-the-tropics/" target="_blank">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="a conceptual diagram showing three satellites overflying a line of convective clouds, illuminating them with their radar beams"
                      src="../images/people/sue_vdh_pic.jpg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      CSU atmospheric scientists lead $177 million NASA mission to study thunderstorms in the Tropics
                    </Card.Title>
                    <Card.Text>
                      From SOURCE-- NASA has announced a $177 million Earth science mission led by Colorado State University that...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on November 14, 2021</small>
                    </Card.Footer>
                  </Card.Body>


                </Card>
              </a>
            </SplideSlide>
            <SplideSlide>
              <a href="https://www.nasa.gov/press-release/nasa-selects-new-mission-to-study-storms-impacts-on-climate-models" target="_blank">
                <Card className="h-100">
                  <div className="card-img">
                    <StaticImage
                      alt="Towering cumulonimbus thunderstorm clouds are seen in this photo taken Aug. 15, 2014, looking east toward the Atlantic Ocean from the Space Launch Complex 37 area at Cape Canaveral Air Force Station (now Cape Canaveral Space Force Station) in Florida"
                      src="./news/images/ksc-2014-3582_large.jpeg"
                      layout="constrained"
                      placeholder="blurred"
                      objectPosition="0 0"
                      aspectRatio={16 / 9}
                      objectFit="contain"
                      transformOptions={{ fit: "cover" }}
                    />
                  </div>

                  <Card.Body>
                    <Card.Title>
                      NASA Selects New Mission to Study Storms, Impacts on Climate Models
                    </Card.Title>
                    <Card.Text>
                      From NASA-- NASA has selected a new Earth science mission that will study the behavior of tropical storms and thunderstorms, including their impacts on weather and...
                    </Card.Text>
                    <Card.Footer>
                      <small className='text-muted'>Posted on November 5, 2021</small>
                    </Card.Footer>
                  </Card.Body>


                </Card>
              </a>
            </SplideSlide>

          </Splide>
          <div className='pt-3'></div>
          <Link to="news/mission-news">
            <Button variant="primary">All News</Button>
          </Link>



        </Container>
      </main >
      <Footer></Footer>
    </React.Fragment >
  )
}

export default IndexPage
